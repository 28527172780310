import React, { useState } from "react"
import { useFormik } from "formik"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

import { Form } from "../components/ContactForm"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Wrapper = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: center;
  flex-direction: row;
`

const Contact = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  const handleSubmit = e =>
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...e }),
    })
      .then(() => setFormSubmitted(true))
      .catch(error => alert(error))

  return (
    <Layout>
      <SEO title="Contact" />
      <Wrapper>
        {!formSubmitted ? (
          <Form
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
            onSubmit={formik.handleSubmit}
          >
            <label htmlFor="name">First Name</label>
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <label htmlFor="email">Email Address</label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <label htmlFor="phone">Phone</label>
            <input
              id="phone"
              name="phone"
              type="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              type="message"
              onChange={formik.handleChange}
              value={formik.values.message}
            />
            <button type="submit">Submit</button>
            <input type="hidden" name="form-name" value="contact" />
          </Form>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h2>Got your message! </h2>
            <h3>I will get back soon, Thank you</h3>
          </div>
        )}
      </Wrapper>
    </Layout>
  )
}

export default Contact
